<!--
 * @Author: lbh
 * @Date: 2021-04-28 18:17:14
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-28 18:33:02
 * @Description: 排隊取票
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="ricepon"
      child='lineUp'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="排隊取票"
        content="自助取票方便快捷"
        :type="4"
        :wtype="window.type"
        :image="getImgPath('Ricepon_products_top02-1.jpg')"
        :bgImage="getImgPath('pbannerqq.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        title="排隊取票"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0
      },
    }
  },
  mounted () {
    util.setTitle('排隊取票 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [util.getImgPath('Que-es-gestion-de-colas.jpg')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: "",
        items: [
          { title: '遠程取號無需等位' },
          { title: '支持APP遠程取號' },
          { title: '顧客可以在家、在公司等任何地方排隊取號自動提醒顧客準備入場' }
        ]
      },
    ];
    this.graphic.datas = datas;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.2);
      if (w < 700) {
        this.window.type = '600';
        // this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        // this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
